<template>
  <div class="container-filter-page">
    <div class="header">
      <div class="clear" @click="clearFiltersPage()">
        <span>Очистить</span>
      </div>
      <div class="filter">
        <span>Фильтры</span>
      </div>
      <div class="close" @click="closePage()">
        <span>Закрыть</span>
      </div>
    </div>
    <div class="container-filter-block">
      <div class="type"
           v-if="settings.isEnabledTypes"
           v-show="visibleFilters.isVisibleTypes">
        <p class="title">Тип</p>
        <div class="filter-button-container">
          <label
              v-if="settings.types.POSTAMATS"
              v-show="visibleFilters.types.POSTAMATS">
            <input type="checkbox" style="display: none" v-model="filters.types.POSTAMATS">
            <div class="filter-button">Постамат</div>
          </label>
          <label v-if="settings.types.PICKUP_POINTS"
                 v-show="visibleFilters.types.PICKUP_POINTS">
            <input type="checkbox" style="display: none" v-model="filters.types.PICKUP_POINTS">
            <div class="filter-button">Пункт выдачи</div>
          </label>
          <label
              v-if="settings.types.STORES"
              v-show="visibleFilters.types.STORES">
            <input type="checkbox" style="display: none" v-model="filters.types.STORES">
            <div class="filter-button">Магазин</div>
          </label>
        </div>
      </div>
      <div class="service"
           v-if="settings.isEnabledServices"
           v-show="visibleFilters.isVisibleServices">
        <p class="title">Услуга</p>
        <div class="filter-button-container">
          <label
              v-if="settings.services.PARTIAL_RETURN"
              v-show="visibleFilters.services.PARTIAL_RETURN">
            <input type="checkbox" style="display: none" v-model="filters.services.PARTIAL_RETURN">

            <ServiceFilterButton :service="descriptions.services['PARTIAL_RETURN']"></ServiceFilterButton>
          </label>
          <label
              v-if="settings.services.TRY_ON"
              v-show="visibleFilters.services.TRY_ON">
            <input type="checkbox" style="display: none" v-model="filters.services.TRY_ON">

            <ServiceFilterButton :service="descriptions.services['TRY_ON']"></ServiceFilterButton>
          </label>
          <label
              v-if="settings.services.TRY_ON_SHOES_AND_OUTERWEAR"
              v-show="visibleFilters.services.TRY_ON_SHOES_AND_OUTERWEAR">
            <input type="checkbox" style="display: none"
                   v-model="filters.services.TRY_ON_SHOES_AND_OUTERWEAR">

            <ServiceFilterButton :service="descriptions.services['TRY_ON_SHOES_AND_OUTERWEAR']"></ServiceFilterButton>
          </label>
        </div>
      </div>
      <div class="carrier"
           v-if="settings.isEnabledCarriers"
           v-show="visibleFilters.isVisibleCarries">
        <p class="title">Доставщик</p>
        <div class="filter-button-container">
          <label
              v-if="settings.carriers.Hermes && settings.types.PICKUP_POINTS"
              v-show="visibleFilters.carriers.Hermes">
            <input type="checkbox" style="display: none" v-model="filters.carriers.Hermes">

            <div class="filter-button">Hermes</div>
          </label>
          <label
              v-if="settings.carriers.PickPoint && settings.types.PICKUP_POINTS"
              v-show="visibleFilters.carriers.PickPoint">
            <input type="checkbox" style="display: none" v-model="filters.carriers.PickPoint">
            <div class="filter-button">PickPoint</div>
          </label>
          <label
              v-if="settings.carriers.Boxberry && settings.types.PICKUP_POINTS"
              v-show="visibleFilters.carriers.Boxberry">
            <input type="checkbox" style="display: none" v-model="filters.carriers.Boxberry">

            <div class="filter-button">BoxBerry</div>
          </label>
          <label
              v-if="settings.carriers.FivePost && settings.types.PICKUP_POINTS"
              v-show="visibleFilters.carriers.FivePost">
            <input type="checkbox" style="display: none" v-model="filters.carriers.FivePost">

            <div class="filter-button">5post</div>
          </label>
          <label
              v-if="settings.carriers.Ozon && settings.types.PICKUP_POINTS"
              v-show="visibleFilters.carriers.Ozon">
            <input type="checkbox" style="display: none" v-model="filters.carriers.Ozon">

            <div class="filter-button">Ozon</div>
          </label>
          <label
              v-if="settings.carriers.Store && settings.types.STORES"
              v-show="visibleFilters.carriers.Store">
            <input type="checkbox" style="display: none" v-model="filters.carriers.Store">

            <div class="filter-button">Доставка в магазин</div>
          </label>
        </div>
      </div>
    </div>
    <div class="button-container"
         v-if="settings.isEnabledServices || settings.isEnabledTypes || settings.isEnabledCarriers"
         v-show="visibleFilters.isVisibleCarries || visibleFilters.isVisibleServices || visibleFilters.isVisibleTypes">
      <div class="button blue vertical-center" @click="filter">
        Применить
      </div>
    </div>
    <div class="button-container" style="height: fit-content; margin-top: 30px" v-else>
      <div class="button vertical-center disabled"
           style="width:100%; color: #cdcdcd;border: 1px solid #cdcdcd;height: fit-content">Нет активных фильтров
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

import Points from '../helpers/Points';
import Filters from '../helpers/Filters';
import Utils from "../helpers/Utils";
import ServiceFilterButton from "./service-filter-button";
import ServicesDescriptionMapper from "../helpers/ServicesDescriptionMapper";

export default {
  name: 'container-filter-page',
  components: {ServiceFilterButton},
  data() {
    return {
      actualActiveFilters: [],
      filters: {
        carriers: {},
        types: {},
        services: {}
      },
      settings: {
        isEnabledCarriers: true,
        isEnabledTypes: true,
        isEnabledServices: true,
        carriers: {},
        types: {},
        services: {}
      },
      visibleFilters: {
        isVisibleCarries: true,
        isVisibleTypes: true,
        isVisibleServices: true,
        carriers: {},
        types: {},
        services: {}
      },
      descriptions: {
        services: {}
      }
    }
  },
  computed: {...mapGetters(['getPrevState', 'getIsFilterActive', 'getActiveFilters', 'getCurrentState', 'getFilterSettings'])},
  watch: {
    getCurrentState(val) {
      if (val === 'FilterPage') {
        this.filters = JSON.parse(JSON.stringify(this.getActiveFilters))
      }
    },

    getActiveFilters(val) {
      this.filters = JSON.parse(JSON.stringify(val));
    },

    getIsFilterActive() {
      this.filters = JSON.parse(JSON.stringify(this.getActiveFilters));
    }
  },
  mounted: function () {
    const visibleCarriers = Points.getVisibleCarriers();
    const visibleServices = Points.getVisibleServices();
    const visibleTypes = Points.getVisibleTypes();

    this.visibleFilters.isVisibleCarries = Utils.isAnyTrue(visibleCarriers);
    this.visibleFilters.isVisibleTypes = Utils.isAnyTrue(visibleTypes);
    this.visibleFilters.isVisibleServices = Utils.isAnyTrue(visibleServices);

    this.visibleFilters.carriers = visibleCarriers;
    this.visibleFilters.types = visibleTypes;
    this.visibleFilters.services = visibleServices;

    this.settings.isEnabledCarriers = Utils.isAnyTrue(this.getFilterSettings.carriers) && Utils.isAnyTrue(
        this.getFilterSettings.types
    );
    this.settings.isEnabledServices = Utils.isAnyTrue(this.getFilterSettings.services);
    this.settings.isEnabledTypes = Utils.isAnyTrue(this.getFilterSettings.types);

    this.settings.types = this.getFilterSettings.types;
    this.settings.carriers = this.getFilterSettings.carriers;
    this.settings.services = this.getFilterSettings.services;

    this.descriptions.services = ServicesDescriptionMapper.map(Points.pointsResult.servicesDescription);
  },
  created() {
    this.setFilters(this.visibleFilters);
  },
  methods: {
    ...mapMutations(['setRightPanelState', 'setActiveFilters', 'setFilters', 'setMapLoader', 'setRightPanelLoader']),

    closePage() {
      this.$parent.$parent.toogleToolbarMapList(true);
      this.setRightPanelState('PointsList');
    },

    clearFiltersPage() {
      this.filters = Filters.FILTER_SETTINGS;
      this.setActiveFilters([]);
    },

    filter() {
      this.actualActiveFilters = this.filters;
      this.setActiveFilters(JSON.parse(JSON.stringify(this.actualActiveFilters)));

      if (typeof this.$parent.$parent.toogleToolbarMapList !== 'undefined') {
        this.closePage();
      }
    }
  }
}
</script>
