export default {
    POINT_TYPE_POSTAMAT: 1,
    POINT_TYPE_PICKUP_POINT: 2,
    POINT_TYPE_SHOP: 3,
    TYPES_NAMES: {
        '1': 'POSTAMATS',
        '2': 'PICKUP_POINTS',
        '3': 'STORES'
    },
    FILTER_SETTINGS: {
        types: {
            POSTAMATS: false,
            PICKUP_POINTS: false,
            STORES: false,
        },
        carriers: {
            PickPoint: false,
            Hermes: false,
            Boxberry: false,
            FivePost: false,
            Ozon: false,
            Store: false
        },
        services: {
            PARTIAL_RETURN: false,
            TRY_ON: false,
            TRY_ON_SHOES_AND_OUTERWEAR: false
        },
        disableFilters: false
    },
}
