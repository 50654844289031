import {map, parseInt} from "lodash";
// Icons for Yandex Maps
import EllipseIcon from '../assets/svg/Icon/Ellipse.svg';
import FivePostIcon from '../assets/svg/CompanyPins/5post.svg';
import BoxberryIcon from '../assets/svg/CompanyPins/Boxberry.svg';
import HermesIcon from '../assets/svg/CompanyPins/Hermes.svg';
import PickPointIcon from '../assets/svg/CompanyPins/PickPoint.svg';
import YouIcon from '../assets/svg/Icon/You.svg';
import OzonIcon from '../assets/svg/CompanyPins/Ozon.svg';
// Provider for Yandex Maps
import ProxyGeocoder from "./ProxyGeocoder";
// Helpful functions
import Utils from "./Utils";
import Points from "./Points";

export default class {
    static #instance = null;
    /**
     *
     * https://yandex.ru/dev/maps/jsapi/doc/2.1/dg/concepts/many-objects.html/?from=jsapi
     */
    ObjectManager;

    /**
     * Единное пространство имен для дальнейшей инициализации модулей
     * this.ymaps.Ballon или this.ymaps.Hint итд
     */
    ymaps;
    filterState;
    carriersFilter;

    /**
     * Корневое API для работы с картой
     * У карты есть четыре глобальных коллекции: коллекция геообъектов, коллекция элементов управления, коллекция слоев
     * и коллекция поведений карты.
     *
     * Дальнейшие манипуляции будут происходить для добавления и удаления элементов
     *
     * myMap.geoObjects.add(myGeoObject) — добавление объекта на карту,
     * myMap.geoObjects.remove(myGeoObject) — удаление объекта с карты.
     *
     * Также для
     * myMap.setCenter([55.7, 37.6], 6);
     * myMap.panTo([50.451, 30.522], {duration: 2000});
     * myMap.setBounds([[50.1, 30.2],[60.3, 20.4]]);
     *
     *
     * Элементом верхнего уровня в этой иерархии является карта. Через карту можно задать опции как самой карте, так и
     * всем объектам, относящимся к ней: геообъектам, элементам управления, слоям, а также их коллекциям и т.д.
     */
    MapYandex;

    /**
     * Сформированные данные для яндекс карт
     * для ObjectManager
     * FeatureCollection
     */
    DataForMap;

    /**
     * this.ymaps.geolocation яндекс карт
     */
    GeoLocation;

    /**
     * Пользовательские данные из строки браузера &shop
     */
    address;

    /**
     * Точка последнего поиска
     */
    searchPoint;

    /**
     * Провайдер для Яндекс карт
     * Ссылается на наш микросервис microservice-geocoder
     */
    ProxyGeocoder;

    /**
     * сохраняем в поле id метки, по которой кликнули
     */
    currentIdClicked;

    /** опции настроек для Placemark: по-умолчанию, при клике */
    placemarkOptions = {
        default: {
            iconImageSize: [36, 50],
            iconImageOffset: [-12, -36],
            zIndex: 10
        },
        clicked: {
            iconImageSize: [50, 75],
            iconImageOffset: [-19, -62],
            zIndex: 100
        }
    };

    /**
     * Опции по умолчанию для инициализации карты в this.CreateMap
     * @type {{controls: [], center: number[], zoom: number}}
     */
    defaultOptions = {
        center: [55.751574, 37.573856],
        zoom: 3,
        controls: []
    };

    static getInstance(ymaps) {
        if (this.#instance === null) {
            this.#instance = new this();
            if (ymaps) {
                this.#instance.setVars(ymaps);
            }

        }
        return this.#instance;
    }

    setVars(ymaps) {
        this.ymaps = ymaps
        // this.visiblePoints = false;
        this.MapYandex = false;
        this.DataForMap = false;
        this.ObjectManager = false;
        this.GeoLocation = this.ymaps.geolocation
        this.ProxyGeocoder = {
            geocode: ProxyGeocoder.geocode.bind(this),
            suggest: ProxyGeocoder.suggest.bind(this)
        };
        // this.clusterer = false;

        this.address = this.setAddress();

    }

    setPoints(jsonResult) {
        this.DataForMap = this.createDataForMap(jsonResult.points, jsonResult.pointFields, jsonResult.carriersMap);
        this.ObjectManager.add(this.DataForMap);
        this.MapYandex.geoObjects.add(this.ObjectManager);
    }

    setAddress() {
        let address = Utils.getUrlParameter('address') || false;
        if (address) {
            address = decodeURIComponent(address).trim();
        }
        return address;
    }

    getMyMap() {
        return this.MapYandex;
    }

    getDeliveryPrice() {
        return parseInt(Utils.getUrlParameter('deliveryCost'));
    }

    createYMapsObjectManagerData() {
        return new this.ymaps.ObjectManager({
            clusterize: true,
            gridSize: 64,
            clusterDisableClickZoom: false,
            geoObjectOpenBalloonOnClick: false,
            clusterHideIconOnBalloonOpen: true,
            geoObjectHideIconOnBalloonOpen: true,
        })
    }

    /**
     * Метод для инициализации карты
     *
     * 1) получить адресс
     * 2) пробить его
     * 3) выставить по этому адрессу поинт ВЫ
     * 4) создать карту CreateMap
     *
     * @returns {Promise<unknown>}
     */
    init() {
        return new Promise((resolve) => {
            this.ymaps.ready(() => {

                if (this.address) {
                    // если есть адрес
                    try {
                        this.ymaps.geocode(this.address, {
                            results: 1,
                            // provider: this.ProxyGeocoder
                        }).then((res) => {

                            if (res.geoObjects.getLength() > 0) {
                                // если есть
                                var mapContainer = document.querySelector('#map'),
                                    bounds = res.geoObjects.get(0).properties.get('boundedBy'),
                                    // Рассчитываем видимую область для текущей положения пользователя.
                                    mapState = this.ymaps.util.bounds.getCenterAndZoom(
                                        bounds,
                                        [mapContainer.clientWidth, mapContainer.clientHeight]
                                    );
                                this.createMap(mapState);
                                resolve(this);
                            } else {
                                this.createMap();
                                resolve(this);
                            }

                        }, () => {
                            // при false promisa
                            this.createMap();
                            resolve(this);
                        });
                    } catch (e) {
                        // если ошибка
                        this.createMap();
                        resolve(this);

                    }
                } else {
                    try {
                        this.GeoLocation.get({autoReverseGeocode: false, mapStateAutoApply: false}).then((res) => {
                            if (res.geoObjects.getLength() > 0) {

                                this.createMap({
                                    center: res.geoObjects.get(0).geometry.getCoordinates(),
                                    zoom: 13,
                                    controls: []
                                });

                                res.geoObjects.options.set('iconLayout', 'default#image');
                                res.geoObjects.options.set('iconImageHref', YouIcon);
                                res.geoObjects.options.set('iconImageSize', [50, 80]);
                                res.geoObjects.options.set('iconImageOffset', [-25, -40]);

                                this.MapYandex.geoObjects.add(res.geoObjects);

                            } else {
                                this.createMap();
                            }
                            resolve(this);
                        }, () => {
                            this.createMap();
                            resolve(this);
                        })
                    } catch (e) {
                        console.log(e);
                    }

                }
            });
        });
    }

    /**
     * Инициализация карты
     * @param options
     */
    createMap(options) {
        try {

            if (!options) {
                options = this.defaultOptions;
            }

            // задаем параметры по умолчанию
            options.controls = ['geolocationControl', 'searchControl'];
            options.suppressMapOpenBlock = true;

            this.MapYandex = new this.ymaps.Map('map', options);

            // Создадим элемент управления масштабом маленького размера и добавим его на карту.
            var zoomControl = new this.ymaps.control.ZoomControl({
                options: {
                    size: "small",
                    position: {
                        top: '50px',
                        bottom: 'auto',
                        left: '10px'

                    }
                }
            });
            this.MapYandex.controls.add(zoomControl);
            this.ObjectManager = this.createYMapsObjectManagerData();

            /**
             * Добавляем и все на карту и настраиваем отображение
             */
            var clusterIcons = [
                {
                    href: EllipseIcon,
                    size: [25, 25],
                    // Отступ, чтобы центр картинки совпадал с центром кластера.
                    offset: [-12, -12]
                },
                {
                    href: EllipseIcon,
                    size: [30, 30],
                    // Отступ, чтобы центр картинки совпадал с центром кластера.
                    offset: [-10, -10]
                },
                {
                    href: EllipseIcon,
                    size: [40, 40],
                    offset: [-20, -20]
                },
                {
                    href: EllipseIcon,
                    size: [50, 50],
                    offset: [-20, -20]
                },
                {
                    href: EllipseIcon,
                    size: [70, 70],
                    offset: [-50, -50]
                },
            ];
            // При размере кластера до 100 будет использована картинка 'small.jpg'.
            // При размере кластера больше 100 будет использована 'big.png'.
            let clusterNumbers = [10, 20, 40, 100];


            this.ObjectManager.clusters.options.set('clusterIcons', clusterIcons);
            this.ObjectManager.clusters.options.set('clusterNumbers', clusterNumbers);
            this.ObjectManager.clusters.options.set(
                'clusterIconContentLayout',
                this.ymaps.templateLayoutFactory.createClass(
                    '<div style="color: #FFFFFF;  text-align: center; font-size:9px;">{{ properties.geoObjects.length }}</div>'
                ));
            var customBalloonContentLayout = this.ymaps.templateLayoutFactory.createClass([
                '<ul class=list style="list-style: none outside;">',
                '{% for geoObject in properties.geoObjects %}',
                '<li style="padding: 5px 10px; border: 1px solid #cccccc; margin-bottom: 5px; border-radius: 3px;"><a style="color:#207697;display: block;" href=# onclick="getPointById(this.dataset.point)" data-point="{{ geoObject.id }}" class="list_item">{{ geoObject.properties.balloonContentBody|raw }}</a></li>',
                '{% endfor %}',
                '</ul>'
            ].join(''));

            this.ObjectManager.clusters.options.set('balloonContentLayout', customBalloonContentLayout);

            this.MapYandex.geoObjects.events
                .add('balloonopen', function () {
                    if (window.innerWidth < 660) {
                        document.querySelector('.settings-map').style.zIndex = -1
                    }
                })
                .add('balloonclose', function () {
                    document.querySelector('.settings-map').style.zIndex = 1
                });


            this.MapYandex.geoObjects.add(this.ObjectManager)

            //Добавляем событие клика на иконку для ObjectManager => увеличиваем размер
            this.MapYandex.geoObjects.events.add('click', (e) => {
                this.handleClickByPlacemark(e.get('objectId'));
            });

            //Добавляем событие клика на иконку
            this.MapYandex.geoObjects.events.add('click', (e) => {
                const objectId = e.get('objectId');
                this.ObjectManager.objects.getById(objectId);
            });


        } catch (e) {
            console.log(e);

        }
    }

    /**
     * обработчик клика на метку
     * @param pointId
     */
    handleClickByPlacemark(pointId) {
        this.ObjectManager.objects.setObjectOptions(pointId, this.placemarkOptions.clicked);

        if (this.currentIdClicked && this.currentIdClicked !== pointId) {
            this.setDefaultOptionsInPlacemark();
        }

        this.currentIdClicked = pointId;
    }

    /**
     * устанавливаем опции параметров по-умолчанию для текущей активной метки
     */
    setDefaultOptionsInPlacemark() {
        this.ObjectManager.objects.setObjectOptions(
            this.currentIdClicked,
            this.placemarkOptions.default
        );
    }

    createDataForMap(points, pointFields, carriersMap) {

        let DataForMap = {
            type: "FeatureCollection",
            features: []
        };


        let latitudeIndex = pointFields.indexOf('latitude');
        let longitudeIndex = pointFields.indexOf('longitude');
        let addressIndex = pointFields.indexOf('address');
        let costIndex = pointFields.indexOf('cost');
        let carrierIndex = pointFields.indexOf('carrier');
        let pointTypeIndex = pointFields.indexOf('point_type');
        let tryOnAllIndex = pointFields.indexOf('try_on_all');
        let tryOnPartialIndex = pointFields.indexOf('try_on_partial');
        let partialReturnIndex = pointFields.indexOf('partial_return');

        function resolveCompanyPin(companyId) {
            var shopId = Utils.getUrlParameter('shop');
            var Store = {
                'href': '',
                'height': 40,
                'width': 32
            }

            try {
                Store.href =  require(`../assets/svg/CompanyPins/Store${shopId}.svg`);
                Store.height = 50;
                Store.width = 36;
            } catch (e) {
                delete Store.href;
            }

            var mapping = {
                'Hermes': {
                    'href': HermesIcon,
                    'height': 50,
                    'width': 36
                },
                'Boxberry': {
                    'href': BoxberryIcon,
                    'height': 50,
                    'width': 36
                },
                'PickPoint': {
                    'href': PickPointIcon,
                    'height': 50,
                    'width': 36
                },
                'FivePost': {
                    'href': FivePostIcon,
                    'height': 50,
                    'width': 36
                },
                'Ozon': {
                    'href': OzonIcon,
                    'height': 50,
                    'width': 36
                },
                'Store' : Store
            };

            return mapping[carriersMap[companyId]] || '';
        }

        function HintContent(item) {

            let transit = Points.getTransit(item, pointFields);
            const deliveryCost = Utils.getUrlParameter('deliveryCost') || item[costIndex];

            return "<div style='position: relative; padding: 15px 20px; font-size:12px;'>" +
                "<div style='margin-bottom:10px'><i class=\"icon address\" style='margin:0px; margin-left:8px'>" +
                "</i><span>" + item[addressIndex] + "</span>" +
                "</div>" +
                "<div> " +
                "<i class=\"icon delivery\" style='margin:0px; margin-left:8px;'></i>" +
                'Доставим за ' + transit + ', ' + "<span style='color:#337AB7'><b>" + deliveryCost + "₽</b></span>" +
                "</div>" +
                "</div>"
        }


        DataForMap.features = map(points, (item, itemId) => {

            let pin = resolveCompanyPin(item[carrierIndex]);

            return {
                type: "Feature",
                id: itemId,
                geometry: {
                    type: "Point",
                    coordinates: [item[latitudeIndex], item[longitudeIndex]]
                },
                properties: {
                    //balloonContentBody: item[carrierIndex] + ': ' + item[addressIndex],
                    balloonContentBody: item[addressIndex],
                    clusterCaption: item[carrierIndex] + ': ' + item[addressIndex],
                    itemId: itemId,
                    carrier: item[carrierIndex],
                    pointType: item[pointTypeIndex],
                    partialReturn: item[partialReturnIndex],
                    tryOnAll: item[tryOnAllIndex],
                    tryOnPartial: item[tryOnPartialIndex],
                    hintContent: HintContent(item),
                },
                options: {
                    iconLayout: 'default#image',
                    iconImageHref: pin.href,
                    iconImageSize: [pin.width, pin.height],
                    hintCloseTimeout: 0,
                    hintPane: 'hint',
                    zIndex: 10
                },
            };

        });

        return DataForMap;

    }

    getVisiblePointIds(DataForMap, Map) {
        let resultIds = [];
        let queryResult = this.ymaps.geoQuery(DataForMap).searchInside(Map);
        let objectManager = this.ObjectManager;

        queryResult._objects.forEach(function (object) {
            let objectState = objectManager.getObjectState(object.properties._data.itemId);
            if (!objectState.isFilteredOut) {
                resultIds.push(object.properties._data.itemId);
            }
        });

        return resultIds;
    }

    /**
     * Добавить событие на карту
     * @param Map
     * @param eventType
     * @param callback
     */
    addEvent(Map, eventType, callback) {
        Map.events.add(eventType, callback);
    }
}
